import {createPortal} from "react-dom";
import {CSSTransition} from "react-transition-group";
import {useRef} from "react";
import {observer} from "mobx-react";
import {overlay} from "@/store/Overlay";

const FullScreenOverlay = observer(({...props}) => {
  const ref = useRef();

  return createPortal(
    <CSSTransition
      nodeRef={ref}
      in={overlay.isOpen}
      timeout={400}
      mountOnEnter
      unmountOnExit
      classNames="fade"
    >
      <div
        {...props}
        ref={ref}
        className={`fixed left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.5)] ${overlay.overHeader ? "z-header" : "z-belowHeader"}`}
        onClick={overlay.close}
        onMouseOver={overlay.onMouseOver}
      ></div>
    </CSSTransition>,
    document.body,
  );
});

export default FullScreenOverlay;
